import React, { useContext } from 'react';
import { sortByNumerical } from '@codiwork/codi';

import { media } from 'context';
import Clickable from 'ds/Clickable';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { DAYS_OF_WEEK_ORDERED } from 'ds/constants';

interface Props {
  selected: number[];
  onSelect: (selected: number[]) => void;
  disabled?: boolean;
}

const DaysOfWeekSelector: React.FC<Props> = ({ selected, onSelect, disabled }) => {
  const { xs } = useContext(media);

  return (
    <Layout {...(xs ? {} : { align: 'center', justify: 'space-between' })}>
      <Layout align="center" {...(xs ? { marginTop: 16, wrap: true } : {})}>
        {DAYS_OF_WEEK_ORDERED.map((day, index) => {
          const isSelected = selected.includes(day.ordinal);

          return (
            <Clickable
              onClick={() => {
                const updated = [...selected];
                if (isSelected) {
                  updated.splice(updated.indexOf(day.ordinal), 1);
                } else {
                  updated.push(day.ordinal);
                }

                onSelect(updated.sort(sortByNumerical));
              }}
              key={day.abbreviation}
              disabled={disabled}
            >
              <Layout
                {...(isSelected ? { color: 'blue-500' } : { color: 'white', borderColor: 'blue-300', borderWidth: 1 })}
                borderRadius="circular"
                align="center"
                justify="center"
                width={xs ? 36 : 40}
                height={xs ? 36 : 40}
                marginLeft={index === 0 ? undefined : 8}
              >
                <Text size="body3" color={isSelected ? 'white' : 'blue-500'} semibold scale>
                  {day.abbreviation}
                </Text>
              </Layout>
            </Clickable>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default DaysOfWeekSelector;
