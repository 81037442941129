import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconNames } from '@codiwork/codi';
import classNames from 'classnames';

import { app, media } from 'context';
import Button from 'ds/Button';
import Icon from 'ds/Icon';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Modal from 'ds/Modal';
import ScrollShadowContainer from 'ds/ScrollShadowContainer';
import Text from 'ds/Text';
import TextButton from 'ds/TextButton';
import { ANIMATION_DURATION, SCROLLABLE_BOX_SHADOW } from 'ds/constants';

export interface Props {
  category: string;
  options: OptionProps[];
  apply: (values: string[]) => void;
  close: () => void;
  dataSet: any[];
  filterFunction: (dataSet: any[], values: any[]) => any[];
  isMobile?: boolean;
  isVisible?: boolean;
}

const CONTROL_HEIGHT = 48;
const PADDING_X = 24;
const PADDING_Y = 24;
const FOOTER_HEIGHT = 80;
const OPTION_HEIGHT = 48;
const WIDTH = 360;

const CheckmarkList: React.FC<Props> = ({
  category,
  options,
  apply,
  close,
  dataSet,
  filterFunction,
  isMobile = false,
  isVisible = true
}) => {
  const mediaContext = useContext(media);
  const xs = mediaContext.xs || isMobile;
  const [localOptions, setLocalOptions] = useState<OptionProps[]>(options);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const { width } = useContext(app);
  const ref = useRef<HTMLDivElement>(null);
  const [openFromRight, setOpenFromRight] = useState<boolean>(false);

  useEffect(() => {
    const container = ref.current;
    if (!container) return;

    const { width: containerWidth, left } = container.getBoundingClientRect();

    // Only determine which side to open from when list is not visible.
    if (containerWidth) return;

    const openFromRight = left + WIDTH + 24 > width ? true : false;

    setOpenFromRight(openFromRight);
  }, [width]);

  const toggleSelectedValue = (value: string) => {
    const newOptions = localOptions.map(option => {
      if (option.value === value) {
        return { ...option, selected: !option.selected };
      }
      return option;
    });
    setLocalOptions(newOptions);
  };

  const clearSelections = () => {
    const newOptions = localOptions.map(option => {
      if (!option.disabled) {
        return Object.assign({}, option, { selected: false });
      }
      return option;
    });
    setLocalOptions(newOptions);
  };

  const applySelections = () => {
    if (localOptions) {
      const values: string[] = localOptions
        .filter(option => option.selected)
        .map(option => {
          return option.value;
        });
      apply(values);
    }
  };

  const numResults = () => {
    const values: string[] = localOptions
      .filter(option => option.selected)
      .map(option => {
        return option.value;
      });
    return filterFunction(dataSet, values).length;
  };

  const Options = localOptions.map(option => (
    <Option
      key={option.value}
      value={option.value}
      label={option.label}
      iconName={option.iconName}
      selected={option.selected}
      disabled={option.disabled}
      toggle={toggleSelectedValue}
    />
  ));

  return xs ? (
    <Modal isVisible={isVisible} onClose={close} isBottomSheet>
      <Layout __style={{ paddingBottom: FOOTER_HEIGHT + PADDING_Y }} height="100%">
        <MobileHeader category={category} clearSelections={clearSelections} close={close} hasScrolled={hasScrolled} />
        <ScrollShadowContainer
          __style={{
            height: `calc(100% - ${PADDING_Y * 2}px)`
          }}
          paddingLeft={PADDING_X}
          paddingRight={PADDING_X}
          overflow="auto"
          setHasScrolled={setHasScrolled}
        >
          {Options}
        </ScrollShadowContainer>
      </Layout>
      {isVisible && (
        <Layout
          justify="center"
          align="center"
          position="fixed"
          bottom={0}
          height={FOOTER_HEIGHT}
          color="white"
          width="100%"
          boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
        >
          <Button onClick={applySelections} size="sm" type="primary" text={`Show ${numResults()} Results`} />
        </Layout>
      )}
    </Modal>
  ) : (
    <ScrollShadowContainer
      __ref={ref}
      setHasScrolled={setHasScrolled}
      overflowY="scroll"
      width={isVisible ? WIDTH : 0}
      maxHeight={isVisible ? 504 : 0}
      opacity={isVisible ? 1 : 0}
      boxShadow="0 3px 6px rgba(0, 0, 0, 0.09)"
      position="absolute"
      borderRadius={12}
      right={openFromRight ? 0 : undefined}
      __style={{
        transitionProperty: 'width, max-height, opacity',
        transitionDuration: `${ANIMATION_DURATION}ms`
      }}
    >
      <Layout paddingX={PADDING_X} paddingY={PADDING_Y} color="white">
        {Options}
      </Layout>
      <Layout
        justify="space-between"
        paddingX={PADDING_X}
        height={CONTROL_HEIGHT}
        position="sticky"
        bottom={0}
        align="center"
        color="white"
        width="100%"
        borderBottomLeftRadius={12}
        borderBottomRightRadius={12}
        boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
      >
        <TextButton scale color="gray-600" textSize="body2" onClick={clearSelections} text="Clear" />
        <TextButton scale color="blue-500" textSize="body2" onClick={applySelections} text="Apply" />
      </Layout>
    </ScrollShadowContainer>
  );
};

interface MobileHeaderProps {
  category: string;
  clearSelections: () => void;
  close: () => void;
  hasScrolled: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ category, clearSelections, close, hasScrolled }) => {
  return (
    <Layout
      position="sticky"
      justify="space-between"
      align="center"
      color="white"
      width="100%"
      zIndex={2}
      paddingTop={PADDING_Y}
      paddingX={PADDING_X}
      paddingBottom={16}
      top={0}
      boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
    >
      <TextButton scale color="gray-600" textSize="body2" onClick={clearSelections} text="Clear" />
      <Text bold={true} size="body1" scale>
        Choose {category}
      </Text>
      <IconButton onClick={close} name="close" size="sm" type="primary" />
    </Layout>
  );
};

export interface OptionProps {
  value: string;
  label: string;
  iconName?: IconNames[number];
  selected: boolean;
  disabled: boolean;
  toggle: (value: string) => void;
}

const Option: React.FC<OptionProps> = ({ value, label, iconName, selected, disabled, toggle }) => {
  const { xs } = useContext(media);

  return (
    <div
      onClick={_evt => {
        if (!disabled) toggle(value);
      }}
    >
      <Layout
        __className={classNames(
          'CheckmarkList-option',
          selected ? 'is-selected' : 'is-not-selected',
          xs ? 'is-mobile' : 'is-desktop'
        )}
        height={OPTION_HEIGHT}
        paddingY={4}
        align="center"
        cursor="default"
      >
        {iconName && (
          <Layout justify="center" align="center" height={40} width={40} color="gray-100" borderRadius={12}>
            <Icon size={'md'} name={iconName} color="black" />
          </Layout>
        )}
        <Layout marginLeft={12} equalWidth={true} __className="Option-label">
          <Text bold={selected} size="body1" scale>
            {label}
          </Text>
        </Layout>
        <Layout __className="CheckmarkList-checkmark" justify="flex-end" align="center" opacity={selected ? 1 : 0}>
          <Icon name="checkmark" size={'md'} color={disabled ? 'gray-600' : 'blue-500'} />
        </Layout>
      </Layout>
    </div>
  );
};

export default CheckmarkList;
