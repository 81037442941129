import React, { useRef } from 'react';
import { EditableImage } from '@codiwork/codi';

import Clickable from 'ds/Clickable';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';

import TaskImageUploadThumbnail from './TaskImageUploadThumbnail';
import { Size } from './Text';

interface Props {
  images: EditableImage[];
  onChange: (files: File[]) => void;
  onDeleteClick?: (image: EditableImage) => void;
  disabled?: boolean;
  countPerRow: number;
  maxImages?: number;
  label?: string;
  labelSize?: Size;
}

const TaskImageUploader: React.FC<Props> = ({
  onChange,
  onDeleteClick,
  disabled,
  countPerRow,
  maxImages,
  label,
  labelSize = 'body2',
  ...props
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const showFileChooser = () => hiddenFileInputRef.current?.click();
  const imageDimension = 98;

  const images = props.images.filter(i => !i._destroy);

  return (
    <Layout>
      {images.length ? (
        <Layout flex wrap position="relative" left={-6} width="calc(100% + 12px)">
          {images.map(image => {
            return (
              <TaskImageUploadThumbnail
                key={image.id || image.url}
                containerProps={{ padding: 6, width: `${100 / countPerRow}%` }}
                disabled={disabled}
                image={image}
                onDeleteClick={!!onDeleteClick ? () => onDeleteClick(image) : undefined}
              />
            );
          })}
          {images.length < (maxImages || 12) && (
            <Layout padding={6}>
              <Clickable onClick={showFileChooser} style={{ borderRadius: 8 }} disabled={disabled}>
                <Layout
                  align="center"
                  justify="center"
                  color="gray-25"
                  width={imageDimension}
                  height={imageDimension}
                  borderRadius={8}
                >
                  <Icon name="plus" size="md" color="gray-200" />
                </Layout>
              </Clickable>
            </Layout>
          )}
        </Layout>
      ) : (
        <Clickable onClick={showFileChooser} style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="attachment" size="xs" color="blue-500" />
          <Text size={labelSize} color="blue-500">
            {label || 'Attach image'}
          </Text>
        </Clickable>
      )}
      <input
        ref={hiddenFileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/png, image/jpg, image/heic"
        multiple={maxImages !== 1}
        onChange={event => {
          const files = event.target.files;

          if (!files) return;

          onChange(Array.from(files).slice(0, 12 - images.length));
        }}
      />
    </Layout>
  );
};

export default TaskImageUploader;
