import { City, Country, CustomerProduct, Market, StartupResponse, Updatable, updateInList } from '@codiwork/codi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ButterPage } from 'lib/butter/types';

import { AppLocation } from './types';

type AppState = {
  countries: Country[];
  anonymousId: string | null;
  cmsPages: ButterPage[];
  locations: AppLocation[];
  state: State;
  cities?: City[];
  markets: Market[];
  googleOAuthLoaded: boolean;
  googleMapsLoaded: boolean;
  products: CustomerProduct[];
};

type State = 'loading' | 'ready';

const initialState: AppState = {
  countries: [],
  markets: [],
  anonymousId: null,
  cmsPages: [],
  locations: [],
  state: 'loading',
  googleOAuthLoaded: false,
  googleMapsLoaded: false,
  products: []
};

const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setStartup: (state, { payload: { countries, cities, markets, products } }: PayloadAction<StartupResponse>) => {
      state.countries = countries;
      state.cities = cities;
      state.markets = markets;
      state.products = products;
    },
    setAnonymousId: (state, { payload }: PayloadAction<string | null>) => {
      state.anonymousId = payload;
    },
    setCmsPages: (state, { payload }: PayloadAction<ButterPage[]>) => {
      state.cmsPages = payload;
    },
    setButterLocations: (state, { payload }: PayloadAction<AppLocation[]>) => {
      state.locations = payload;
    },
    setState: (state, { payload }: PayloadAction<State>) => {
      state.state = payload;
    },
    updateButterLocationById: (state, { payload }: PayloadAction<Updatable<AppLocation>>) => {
      updateInList(state.locations, payload);
    },
    setGoogleOAuthLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.googleOAuthLoaded = payload;
    },
    setGoogleMapsLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.googleMapsLoaded = payload;
    }
  }
});

export const { actions } = appSlice;

export default appSlice.reducer;
