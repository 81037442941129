import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { COLORS, Color } from '@codiwork/codi';
import { Boundary, Padding, Placement, PositioningStrategy, Rect } from '@popperjs/core';
import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import { app } from 'context';
import Text from 'ds/Text';

import { IS_TOUCH_DEVICE } from './constants';
import { useOnClickOutside } from './helpers';

export interface Props {
  size?: 'sm' | 'lg';
  content: string | JSX.Element;
  containerStyle?: React.CSSProperties;
  popoverStyle?: React.CSSProperties;
  color?: Color;
  placement?: Placement;
  suppressOnTouchDevices?: boolean;
  offset?: [number, number] | OffsetsFunction;
  hideOnBlur?: boolean;
  isTooltip?: boolean;
  isVisible?: boolean;
  className?: string;
  wrap?: boolean;
  boundary?: Boundary;
  arrowPadding?: Padding | ((params: { popper: Rect; reference: Rect; placement: Placement }) => Padding);
  stopPropagation?: boolean;
  measureOrdinal?: number | string;
  preventOverflow?: boolean;
  closeOnClickedOutside?: boolean;
  onClickOutside?: VoidFunction;
  strategy?: PositioningStrategy;
  maxWidth?: number;
}

const Popover: React.FC<PropsWithChildren<Props>> = ({
  containerStyle,
  placement = 'auto',
  color = 'blue-500',
  content,
  suppressOnTouchDevices = false,
  children,
  offset = [0, 8],
  hideOnBlur = true,
  isTooltip,
  isVisible,
  wrap = false,
  popoverStyle,
  boundary,
  className,
  arrowPadding = 24,
  stopPropagation = true,
  measureOrdinal = 0,
  preventOverflow = true,
  strategy,
  maxWidth = 328,
  closeOnClickedOutside = true,
  onClickOutside
}) => {
  const { contentPaddingX } = useContext(app);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    strategy,
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: arrowPadding } },
      {
        name: 'offset',
        options: {
          offset
        }
      },
      {
        name: 'preventOverflow',
        enabled: preventOverflow,
        options: { padding: { left: contentPaddingX, right: contentPaddingX }, boundary }
      }
    ]
  });

  useEffect(() => {
    update && update();
  }, [measureOrdinal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isTooltip) return;

    if (isVisible) {
      show();
    } else {
      hide();
    }
  }, [isVisible, isTooltip]); // eslint-disable-line react-hooks/exhaustive-deps

  function show() {
    if (!popperElement) return;

    // Make the tooltip visible
    popperElement.setAttribute('data-show', '');

    // Update its position
    update && update();
  }

  function hide() {
    if (!popperElement) return;

    // Hide the tooltip
    popperElement.removeAttribute('data-show');
  }

  if (IS_TOUCH_DEVICE && suppressOnTouchDevices) return <>{children}</>;

  return (
    <>
      <div
        {...(isTooltip && !IS_TOUCH_DEVICE
          ? {
              onMouseEnter: show,
              onFocus: show,
              onMouseLeave: hide,
              onBlur: hideOnBlur ? hide : undefined
            }
          : {})}
        ref={setReferenceElement}
        className="Popover-container"
        style={containerStyle}
      >
        {children}
      </div>
      <PopoverContent
        content={content}
        color={color}
        isTooltip={isTooltip}
        isVisible={isVisible}
        styles={styles}
        attributes={attributes}
        placement={placement}
        className={className}
        popoverStyle={popoverStyle}
        maxWidth={maxWidth}
        stopPropagation={stopPropagation}
        wrap={wrap}
        setPopperElement={setPopperElement}
        setArrowElement={setArrowElement}
        popperElement={popperElement}
        closeOnClickedOutside={closeOnClickedOutside}
        onClickOutside={onClickOutside}
      />
    </>
  );
};

interface PopoverContentProps
  extends Pick<
      Props,
      | 'content'
      | 'isTooltip'
      | 'isVisible'
      | 'className'
      | 'placement'
      | 'popoverStyle'
      | 'stopPropagation'
      | 'wrap'
      | 'closeOnClickedOutside'
      | 'onClickOutside'
    >,
    Required<Pick<Props, 'color' | 'maxWidth'>> {
  styles: { [key: string]: React.CSSProperties };
  attributes: any;
  setPopperElement: (element: HTMLDivElement | null) => void;
  setArrowElement: (element: HTMLDivElement | null) => void;
  popperElement: HTMLDivElement | null;
}

const PopoverContent: React.FC<PopoverContentProps> = ({
  content,
  color = 'blue-500',
  isTooltip,
  styles,
  attributes,
  placement,
  className,
  popoverStyle,
  maxWidth,
  stopPropagation,
  wrap,
  setPopperElement,
  setArrowElement,
  popperElement,
  closeOnClickedOutside = true,
  onClickOutside,
  ...props
}) => {
  const { contentPaddingX, windowWidth } = useContext(app);
  const isBlack = color === 'black';
  const contentRef = useRef<HTMLDivElement>(null);
  const [clickedOutsideHidden, setClickedOutsideHidden] = useState<boolean>(false);

  const isVisible = clickedOutsideHidden ? false : props.isVisible;

  const style: React.CSSProperties = { ...popoverStyle };

  useOnClickOutside(contentRef, event => {
    if (isTooltip || !isVisible || !closeOnClickedOutside) return;

    if (popperElement && popperElement.contains(event.target as HTMLElement)) {
      return;
    }

    setClickedOutsideHidden(true);
    onClickOutside && onClickOutside();
  });

  if (IS_TOUCH_DEVICE) {
    style.maxWidth = windowWidth - 2 * contentPaddingX;
  } else {
    style.maxWidth = maxWidth;
  }

  return (
    <div
      style={{
        ...styles.popper,
        boxShadow: isBlack ? undefined : '0px 13px 23px 0px #00000026',
        backgroundColor: COLORS[color],
        ...style
      }}
      className={classNames('Popover', className, `is-placement-${placement}`, {
        'is-visible': !!isVisible,
        'is-tooltip': isTooltip,
        'is-popover': !isTooltip,
        'is-content-size-lg': typeof content === 'string' && content.length >= 50
      })}
      {...attributes.popper}
      ref={element => {
        setPopperElement(element);
      }}
      onClick={e => {
        if (!stopPropagation) return;

        e.stopPropagation();
      }}
    >
      <div ref={contentRef}>
        {typeof content === 'string' ? (
          <Text size="body3" color={isBlack ? 'white' : 'gray-900'} wrap={wrap}>
            {content}
          </Text>
        ) : (
          content
        )}
      </div>
      <div
        ref={setArrowElement}
        className="Popover-caret"
        style={{ ...styles.arrow, backgroundColor: COLORS[color] }}
      />
    </div>
  );
};

export default Popover;
