import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';

export interface Props
  extends Pick<ReactQuillProps, 'onChange' | 'value' | 'modules' | 'formats' | 'style' | 'readOnly' | 'placeholder'> {}

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link']
  ]
};

const FORMATS = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link', 'indent'];

const RichTextEditor: React.FC<Props> = ({ value, onChange, modules, formats, readOnly, placeholder }) => {
  return (
    <div className="RequestModal-richTextEditor">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules || MODULES}
        formats={formats || FORMATS}
        bounds=".RequestModal-richTextEditor"
        readOnly={readOnly}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RichTextEditor;
