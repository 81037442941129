import React, { Suspense, useContext, useEffect } from 'react';
import { Redirect, RouteComponentProps, matchPath } from 'react-router-dom';
import { compact } from 'lodash';
import qs from 'qs';

import { DEFAULT_META_DESCRIPTION } from 'helpers/constants';

import Head from 'components/Head';

import { media } from 'context';
import { actions as uiActions } from 'store/UI';
import { selectSelectedOfficeId, selectUIState } from 'store/UI/selectors';
import {
  selectCustomerAdminPlans,
  selectHasCustomerAdminRole,
  selectHasListingAdminRole,
  selectHasPayoutAdminRole,
  selectHasPayoutViewerRole,
  selectHasPropertiesNavItem,
  selectHasSignedPlans,
  selectIsAdmin,
  selectIsContractor,
  selectIsCustomerUser,
  selectIsProspect,
  selectOffices,
  selectReservationWorkspaces,
  selectSelectedOfficeInCustomerAdminOffices,
  selectSwitcheableWorkspaceIds,
  selectUser
} from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showCalendarNavItem } from 'ux/Calendar/utils';
import BottomNavigation from 'ux/Layouts/Shared/Navigation/BottomNavigation';
import CustomerBanner from 'ux/Layouts/Shared/Navigation/CustomerBanner';
import NavigationContainer from 'ux/Layouts/Shared/Navigation/NavigationContainer';
import Sidebar from 'ux/Layouts/Shared/Navigation/Sidebar';
import {
  CALENDAR_NAV_ITEM,
  CONTRACTOR_TASKS_ITEM,
  CUSTOMER_OFFICE_PATHS,
  DASHBOARD_NAV_ITEM,
  FAVORITES_NAV_ITEM,
  NAV_ITEM_ORDER,
  OFFICE_PROFILE_NAV_ITEM,
  OFFICE_REQUEST_NAV_ITEM,
  OFFICE_SEARCH_NAV_ITEM,
  OFFSITES_NAV_ITEM,
  PROPERTIES_ACCOUNTS_NAV_ITEM,
  PROPERTIES_LISTINGS_NAV_ITEM,
  PROPERTIES_PAYOUTS_NAV_ITEM,
  SERVICES_AND_AMENITIES_NAV_ITEM
} from 'ux/Layouts/Shared/Navigation/constants';
import {
  getAdminBottomNavItems,
  getBottomNavItems,
  getMobileAccountItems,
  getMoreNavItems
} from 'ux/Layouts/Shared/utils';

import Page from './Page';
import { ADMIN_PATH, AUTH_PATH, PROPERTIES_PATH } from './paths';
import { Route } from './types';
import { defaultLoginRedirectPath, validUserForRole } from './utils';

interface Props
  extends RouteComponentProps,
    Pick<
      Route,
      'requiredRole' | 'redirect' | 'scrollToTop' | 'customLayout' | 'sideBarIsExpandable' | 'showMobileBottomNav'
    > {
  children: JSX.Element;
  isReady: boolean;
}

const AuthenticatedRoute: React.FC<Props> = ({
  children,
  requiredRole,
  redirect,
  location: { pathname, search },
  scrollToTop,
  isReady,
  customLayout,
  sideBarIsExpandable,
  showMobileBottomNav = true
}) => {
  const user = useAppSelector(selectUser);
  const { isMobile } = useContext(media);
  const isContractor = useAppSelector(selectIsContractor);
  const hasListingRole = useAppSelector(selectHasListingAdminRole);
  const hasPayoutAdminRole = useAppSelector(selectHasPayoutAdminRole);
  const hasPayoutViewerRole = useAppSelector(selectHasPayoutViewerRole);
  const hasCustomerAdminRole = useAppSelector(selectHasCustomerAdminRole);
  const hasSignedPlans = useAppSelector(selectHasSignedPlans);
  const hasCalendarNavItem = showCalendarNavItem({ user });
  const reservationWorkspaces = useAppSelector(selectReservationWorkspaces);
  const hasRequestsNavItem = !!reservationWorkspaces.length;
  const { logout } = qs.parse(search, { ignoreQueryPrefix: true });
  const reduxUxType = useAppSelector(selectUIState).uxType;
  const reduxIsPropertyUX = reduxUxType === 'property';
  const isPropertyUX = pathname.startsWith(PROPERTIES_PATH) || reduxIsPropertyUX;
  const isAdminUx = pathname.startsWith(ADMIN_PATH) || reduxUxType === 'admin';
  const isCustomerUx = reduxUxType === 'customer';
  const hasPropertiesNavItem = useAppSelector(selectHasPropertiesNavItem);
  const offices = useAppSelector(selectOffices);
  const hasOffices = !!offices.length && hasCalendarNavItem;
  const isCustomerUser = useAppSelector(selectIsCustomerUser);
  const isProspect = useAppSelector(selectIsProspect);
  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);
  const customerAdminPlans = useAppSelector(selectCustomerAdminPlans);
  const hasActivePlans = !!customerAdminPlans?.long_term_plans.filter(p => !!p.active_workspace_id).length;
  const switcheableWorkspaceIds = useAppSelector(selectSwitcheableWorkspaceIds);
  const selectedOfficeInCustomerAdminOffices = useAppSelector(selectSelectedOfficeInCustomerAdminOffices);
  const isAdmin = useAppSelector(selectIsAdmin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.id) return;

    let matchedWorkspaceIds = CUSTOMER_OFFICE_PATHS.map(p => {
      const match = matchPath(pathname, { path: p.officePath, exact: true });

      if (!match) return null;

      // @ts-expect-error
      const workspaceId = Number(match.params.workspaceId);

      if (isNaN(workspaceId)) return null;

      return workspaceId;
    });

    matchedWorkspaceIds = compact(matchedWorkspaceIds);

    let matchedOfficeId: number | undefined | null = matchedWorkspaceIds[0];

    if (matchedOfficeId === selectedOfficeId || (selectedOfficeId && !matchedOfficeId)) return;

    if (typeof matchedOfficeId !== 'number') {
      if (customerAdminPlans?.active_workspace_ids?.length) {
        matchedOfficeId = customerAdminPlans.active_workspace_ids[0];
      } else {
        matchedOfficeId = reservationWorkspaces[0]?.id;
      }
    }

    if (!matchedOfficeId || !switcheableWorkspaceIds.includes(matchedOfficeId)) return;

    dispatch(uiActions.setSelectedOfficeId(matchedOfficeId));
  }, [isReady, user?.id, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isReady) return null;

  if (requiredRole && user === null) {
    const pathnameWithSearch = `${pathname}${search}`;
    const toPath = logout ? AUTH_PATH : `${AUTH_PATH}?redirect=${encodeURIComponent(pathnameWithSearch)}`;
    return <Redirect to={toPath} />;
  } else if (requiredRole && user && !validUserForRole({ user, role: requiredRole })) {
    return <Redirect to={redirect || defaultLoginRedirectPath(user)} />;
  }

  const sideBarNavItems = [];

  if (isPropertyUX) {
    if (hasPropertiesNavItem) {
      sideBarNavItems.push(PROPERTIES_LISTINGS_NAV_ITEM);
    }

    if (hasPayoutAdminRole) {
      sideBarNavItems.push(PROPERTIES_ACCOUNTS_NAV_ITEM);
    }

    if (hasPayoutAdminRole || hasPayoutViewerRole) {
      sideBarNavItems.push(PROPERTIES_PAYOUTS_NAV_ITEM);
    }
  } else if (isContractor) {
    sideBarNavItems.push(CONTRACTOR_TASKS_ITEM);
  } else {
    sideBarNavItems.push(DASHBOARD_NAV_ITEM);

    if (isProspect) {
      sideBarNavItems.push(FAVORITES_NAV_ITEM);
    } else if (!hasOffices) {
      sideBarNavItems.push(OFFICE_SEARCH_NAV_ITEM);
    }

    if (hasOffices) {
      sideBarNavItems.push(OFFICE_PROFILE_NAV_ITEM);
    }

    if (hasRequestsNavItem) {
      sideBarNavItems.push(OFFICE_REQUEST_NAV_ITEM);
    }

    if (hasCustomerAdminRole && hasSignedPlans && selectedOfficeInCustomerAdminOffices) {
      sideBarNavItems.push(SERVICES_AND_AMENITIES_NAV_ITEM);
    }

    if (hasCalendarNavItem) {
      sideBarNavItems.push(CALENDAR_NAV_ITEM);
    }

    sideBarNavItems.push(OFFSITES_NAV_ITEM);
  }

  const bottomNavItems = isAdminUx
    ? getAdminBottomNavItems()
    : getBottomNavItems({
        hasCalendarNavItem,
        hasCustomerAdminRole,
        hasPayoutAdminRole,
        hasPayoutViewerRole,
        hasRequestsNavItem,
        isPropertyUX,
        isContractor,
        hasListingRole,
        hasOffices,
        isCustomerUser,
        isProspect,
        hasSignedPlans,
        selectedOfficeInCustomerAdminOffices,
        hasActivePlans
      });

  const moreNavItems = getMoreNavItems({
    hasCalendarNavItem,
    hasCustomerAdminRole,
    hasPayoutAdminRole,
    hasPayoutViewerRole,
    hasRequestsNavItem,
    isPropertyUX,
    isContractor,
    hasListingRole,
    hasOffices,
    isCustomerUser,
    isProspect,
    hasSignedPlans,
    selectedOfficeInCustomerAdminOffices,
    hasActivePlans
  });

  sideBarNavItems.sort((item1, item2) => NAV_ITEM_ORDER.indexOf(item1.label) - NAV_ITEM_ORDER.indexOf(item2.label));
  const accountItems = getMobileAccountItems({
    hasActivePlans,
    hasPropertiesNavItem,
    isAdmin
  });

  return (
    <>
      <Head title="Codi" description={DEFAULT_META_DESCRIPTION} />
      <Page scrollToTop={scrollToTop} requiredRole={requiredRole}>
        {pathname.startsWith(ADMIN_PATH) ? (
          children
        ) : (
          <>
            {isCustomerUx && isCustomerUser && <CustomerBanner />}
            <NavigationContainer>
              {!customLayout && (
                <>
                  {isMobile ? (
                    showMobileBottomNav && (
                      <BottomNavigation
                        items={bottomNavItems}
                        theme="light"
                        moreNavItems={[...moreNavItems, ...accountItems]}
                      />
                    )
                  ) : (
                    <Sidebar
                      theme="dark"
                      items={sideBarNavItems}
                      avatarUrl={user?.avatar_url}
                      isExpandable={sideBarIsExpandable}
                    />
                  )}
                </>
              )}
              <Suspense fallback={null}>{children}</Suspense>
            </NavigationContainer>
          </>
        )}
      </Page>
    </>
  );
};

export default AuthenticatedRoute;
