import React, { useContext } from 'react';

import HeroVideo from 'components/HeroVideo';

import { app, media } from 'context';
import { Layout, Text } from 'ds';

import SearchInput from './SearchInput';

interface Props {}

const HOME_PAGE_HERO_TITLE = (
  <>
    Unlock an office
    <br />
    on your terms
  </>
);
const HOME_PAGE_HERO_SUBTITLE =
  'Discover a private, turnkey office space with the most flexible lease terms on the market.';

const Hero: React.FC<Props> = () => {
  const { xs, sm, md } = useContext(media);
  const { contentPaddingX, windowWidth, navBarHeight } = useContext(app);

  return xs || sm ? (
    <Layout height={`calc(100vh - ${navBarHeight}px)`}>
      <HeroVideo
        srcKey="home-page-video_tvfOtuHwV.mp4"
        height="100vh"
        videoHeight={2000}
        folder="public"
        position="absolute"
        right={0}
        top={-navBarHeight}
        zIndex={0}
      >
        <div
          style={{
            background: '#262626',
            opacity: 0.2,
            width: '100%',
            height: '100%',
            position: 'absolute',
            mixBlendMode: 'multiply'
          }}
        />
        <div
          style={{
            background: 'radial-gradient(circle at 52% 88%, rgba(0, 67, 206, 0) 16%, rgba(0, 67, 206, 0.6) 89%)',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      </HeroVideo>
      <Layout paddingX={contentPaddingX} width={windowWidth} zIndex={1} position="absolute" marginTop={134} left={0}>
        <Layout>
          <Text size="headline-xl" color="white">
            {HOME_PAGE_HERO_TITLE}
          </Text>
          <Layout marginTop={24}>
            <Text tag="h1" size="body-md" color="white" semibold>
              {HOME_PAGE_HERO_SUBTITLE}
            </Text>
          </Layout>
          <Layout marginTop={36}>
            <SearchInput />
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Layout height={700}>
      <HeroVideo
        srcKey="home-page-video_tvfOtuHwV.mp4"
        height={700 + navBarHeight}
        folder="public"
        position="absolute"
        left={0}
        top={-navBarHeight}
        zIndex={0}
      >
        <div
          style={{
            background: '#262626',
            opacity: 0.2,
            width: '100%',
            height: '100%',
            position: 'absolute',
            mixBlendMode: 'multiply'
          }}
        />
        <div
          style={{
            background: 'radial-gradient(circle at 120% 0%, rgba(0, 67, 206, 0) 36%, rgba(0, 67, 206, 0.60) 85%)',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      </HeroVideo>
      <Layout zIndex={1} position="absolute" marginTop={134} {...(md ? { left: 64 } : {})}>
        <Text size="display-lg" color="white">
          {HOME_PAGE_HERO_TITLE}
        </Text>
        <Layout marginTop={36} maxWidth={596}>
          <Text size="headline-xs" tag="h1" color="white">
            {HOME_PAGE_HERO_SUBTITLE}
          </Text>
          <Layout marginTop={36}>
            <SearchInput />
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Hero;
