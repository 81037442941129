import { MutableRefObject, createRef, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useThrottledCallback, useWindowResize } from 'beautiful-react-hooks';
import classNames from 'classnames';

import Layout from 'ds/Layout';
import { APP_CONTENT_GUTTER_BOTTOM } from 'ds/PageLayout/constants';
import Text from 'ds/Text';

import CustomerTableCell from './CustomerTableCell';
import { CustomerTableColumn, CustomerTableRow } from './types';

export interface Props<T extends CustomerTableRow> {
  rows: T[];
  columns: CustomerTableColumn<T>[];
  rowHref?: (row: T) => string;
  isAutoHeight?: boolean;
  minWidth?: number;
}

const CustomerTable = <T extends CustomerTableRow>({
  columns,
  rows,
  rowHref,
  minWidth,
  isAutoHeight = true
}: Props<T>) => {
  const history = useHistory();
  const [tableY, setTableY] = useState<number>();
  const [widths, setWidths] = useState<number[]>([]);
  const columnLength = columns.length;
  const refList = useRef<MutableRefObject<HTMLDivElement | null>[]>(
    new Array(columnLength).fill(null).map(() => createRef())
  );
  const onWindowResize = useWindowResize();

  useEffect(() => {
    handleWidths();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleWidths = () => {
    setWidths([]);
    const updatedWidths = [...widths];
    refList.current.forEach((ref, index) => {
      const width = ref.current?.getBoundingClientRect().width;

      if (!width) return;

      updatedWidths[index] = width;
    });
    setWidths(updatedWidths);
  };

  onWindowResize(useThrottledCallback(handleWidths, [], 50));

  return (
    <Layout flexGrow={1} minWidth={minWidth}>
      <Layout justify="space-between" borderColor="gray-50" borderBottom flex>
        {columns.map(({ name, width, minWidth }, index) => {
          return (
            <Layout
              key={name}
              paddingX={24}
              paddingY={12}
              justify={index === columns.length - 1 ? 'flex-end' : 'flex-start'}
              width={widths[index]}
              flexShrink={width || minWidth ? 0 : undefined}
            >
              <Text size="body-xs" semibold color="gray-700">
                {name}
              </Text>
            </Layout>
          );
        })}
      </Layout>
      <Layout
        flexGrow={1}
        minWidth={minWidth}
        {...(isAutoHeight
          ? {
              onMeasure: ({ y }) => setTableY(y),
              maxHeight: `calc(100vh - ${(tableY || 0) + APP_CONTENT_GUTTER_BOTTOM}px)`,
              overflow: 'auto'
            }
          : {})}
      >
        {rows.map((row, rowIndex) => {
          return (
            <div
              key={row.id}
              {...(rowHref
                ? {
                    onClick: e => {
                      if (e.metaKey) {
                        window.open(rowHref(row), '_blank');
                      } else {
                        history.push(rowHref(row));
                      }
                    }
                  }
                : {})}
              className={classNames('CustomerTable-row', { 'is-clickable': rowHref })}
            >
              {columns.map((column, columnIndex) => {
                return (
                  <CustomerTableCell
                    {...column}
                    __ref={rowIndex === 0 ? refList.current[columnIndex] : undefined}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    row={row}
                    columnLength={columns.length}
                    key={column.name}
                    id={column.key}
                    measuredWidth={rowIndex ? widths[columnIndex] : undefined}
                  />
                );
              })}
            </div>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default CustomerTable;
