import React, { useContext } from 'react';

import { app } from 'context';
import Layout, { Props as LayoutProps } from 'ds/Layout';

interface Props extends LayoutProps {
  type?: 'block';
}

const MobileDivider: React.FC<Props> = ({ type, ...props }) => {
  const { contentPaddingX, width } = useContext(app);

  if (type === 'block') {
    return <Layout {...props} height={8} color="gray-25" position="relative" left={-contentPaddingX} width="100vw" />;
  }

  return (
    <Layout {...props} borderTop borderColor="gray-300" width={width} position="relative" left={-contentPaddingX} />
  );
};

export default MobileDivider;
