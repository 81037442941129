import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, useHistory } from 'react-router-dom';
import {
  ApiErrorResponse,
  getStartup,
  getUser,
  setAnonymousIdHeader,
  setAuthorizationHeader,
  setBaseUrl,
  setGoogleApiKey
} from '@codiwork/codi';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';

import {
  decodeJwt,
  getDefaultToken,
  getToken,
  logout,
  refreshTokenIfExpirationSoon,
  startTokenRefreshCheck
} from 'helpers/auth';
import { getEnvironment, isDevelop } from 'helpers/environment';
import { featureEnabled } from 'helpers/features';

import ErrorBoundary from 'components/ErrorBoundary';
import HubSpotChatWidgetManager from 'components/HubSpotChatWidgetManager';
import SnackbarContainer from 'components/SnackbarContainer';

import { AppProvider } from 'context/app';
import { MediaProvider } from 'context/media';
import { LoadingScreen } from 'ds';
import { isGoogleUserAgent } from 'helpers';
import useLink from 'hooks/useLink';
import useScript from 'hooks/useScript';
import { identify } from 'lib/analytics';
import setupAnalytics from 'lib/analytics/setup';
import { SEARCH_ENTRY_PATH } from 'routes';
import { actions as addPropertyActions } from 'store/AddProperty';
import { actions as appActions } from 'store/App';
import { selectAppState } from 'store/App/selectors';
import { actions as userActions } from 'store/User';
import { selectUser, selectUserId } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { persistor, store } from 'store/store';
import { getUserCalendarData, loadUserCalendar } from 'ux/Calendar/utils';

import Router from './routes/Router';

const AppContainer: React.FC = () => {
  const dispatch = useDispatch();
  const userId = useAppSelector(selectUserId);
  const user = useAppSelector(selectUser);
  const analyticsDisabled = (isDevelop() && !featureEnabled({ name: 'enableSegment' })) || isGoogleUserAgent();
  const [analyticsHandled, setAnalyticsHandled] = useState<boolean>(analyticsDisabled);
  const [styleSheetLoaded, setStyleSheetLoaded] = useState<boolean>(false);
  const analyticsStatus = useScript(
    `https://cdn.segment.com/analytics.js/v1/${process.env.REACT_APP_SEGMENT_WRITE_KEY}/analytics.min.js`,
    {
      disabled: analyticsDisabled,
      setUp: setupAnalytics
    }
  );

  const styleSheetStatus = useLink(
    'https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900',
    { disabled: isGoogleUserAgent() && window.location.pathname.startsWith(SEARCH_ENTRY_PATH) }
  );

  useEffect(() => {
    if (['ready', 'error'].includes(styleSheetStatus)) {
      setStyleSheetLoaded(true);
    }
  }, [styleSheetStatus]);

  const appState = useAppSelector(selectAppState);
  const history = useHistory();

  useEffect(() => {
    try {
      if (analyticsStatus === 'ready') {
        window.analytics?.ready(function () {
          const anonymousId = (window.analytics?.user() as any).anonymousId();
          setAnonymousIdHeader(anonymousId);
          dispatch(appActions.setAnonymousId(anonymousId));
          setAnalyticsHandled(true);
        });
      } else if (analyticsStatus === 'error') {
        setAnalyticsHandled(true);
      }
    } catch {}
  }, [analyticsStatus, dispatch]);

  useEffect(() => {
    if (!userId) return;

    loadUserCalendar(userId);
  }, [dispatch, userId]);

  const handleWindowFocus = async () => {
    const reduxState = store.getState();
    const userId = reduxState.User.user?.id;
    const appState = reduxState.App.state;
    const isHidden = document.visibilityState === 'hidden';

    if (appState !== 'ready' || isHidden || !userId) return;

    const token = getToken();

    if (token) {
      const {
        data: { user_id }
      } = decodeJwt(token);
      if (user_id !== userId) {
        window.location.reload();
      }
    } else {
      logout({ history });
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', handleWindowFocus);

    return () => {
      window.removeEventListener('visibilitychange', handleWindowFocus);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.codi = {
      butterVariables: {}
    };

    setBaseUrl(process.env.REACT_APP_API_BASE_URL);
    setGoogleApiKey(process.env.REACT_APP_GOOGLE_GEOCODE_API);
    setAuthorizationHeader(getDefaultToken());
    startTokenRefreshCheck();
    const environment = getEnvironment();

    if (!isGoogleUserAgent() && environment !== 'development') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory())
          }),
          Sentry.replayIntegration()
        ],
        environment,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // TODO: use this?
        // tracePropagationTargets: ['localhost', /^https:\/\/staging-api\.codi\.com/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: ['recordDroppedEvent is not a function', 'ResizeObserver loop limit exceeded']
      });
    }

    refreshTokenIfExpirationSoon().then(() => {
      let userId: number | null = null;
      const token = getToken();

      if (token) {
        const {
          data: { user_id },
          exp
        } = decodeJwt(token);

        if (exp * 1000 > Date.now()) {
          userId = user_id;
          setAuthorizationHeader(token);
        } else {
          logout({ history });
        }
      }

      getStartup()
        .then(({ data }) => dispatch(appActions.setStartup(data)))
        .catch((error: ApiErrorResponse) => {
          if (error.type === 'HivenException::JwtTokenNotAuthorized') {
            logout({ history });
            getStartup().then(({ data }) => dispatch(appActions.setStartup(data)));
          }
        });

      if (userId) {
        Promise.all([getUser({ id: userId }), getUserCalendarData(userId)])
          .then(([{ data: user }, calendar]) => {
            dispatch(userActions.setUser(user));
            dispatch(userActions.setUserCalendar(calendar));
            dispatch(userActions.setAvailableWorkspaces(calendar.workspaces));
            const { firstname, lastname, email, calling_code, phone } = user;
            dispatch(addPropertyActions.updateUser({ firstname, lastname, email, calling_code, phone }));
            identify(user);
          })
          .catch(() => {
            if (isDevelop()) return;
            // User may have been deleted or token is not valid for some reason, so logout
            logout({ history });
          })
          .finally(() => dispatch(appActions.setState('ready')));
      } else {
        dispatch(userActions.setUser(null));
        dispatch(appActions.setState('ready'));
      }
    });
  }, [dispatch, history]);

  return (
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ''}
          onScriptLoadSuccess={() => dispatch(appActions.setGoogleOAuthLoaded(true))}
        >
          <MediaProvider>
            <AppProvider>
              <ErrorBoundary>
                <Suspense fallback={<LoadingScreen />}>
                  <Router
                    isReady={analyticsHandled && appState === 'ready' && styleSheetLoaded}
                    analyticsLoaded={analyticsHandled}
                  />
                  <SnackbarContainer />
                  {user && <HubSpotChatWidgetManager email={user.email} hubspot_token={user.hubspot_token} />}
                  <div id="codi-date-picker-portal" />
                </Suspense>
              </ErrorBoundary>
            </AppProvider>
          </MediaProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </PersistGate>
  );
};

export default AppContainer;
