import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { getBaseUrl, isProduction } from 'helpers/environment';
import { showSnackbar } from 'helpers/snackbar';

import { launchChatWidget } from 'components/HubSpotChatWidgetManager';

import {
  APP_BANNER_HEIGHT,
  HEADER_Z_INDEX,
  Layout,
  NavItem,
  SIDEBAR_EXPANDED_WIDTH,
  SIDEBAR_PADDING_X,
  SIDEBAR_PRIMARY_WIDTH
} from 'ds';
import { APP_ROOT_PATH, QA_LINK, REFERRALS_PATH } from 'routes';
import { SUPPORT_LABEL } from 'routes/constants';
import { selectUIState } from 'store/UI/selectors';
import { actions as userActions } from 'store/User';
import { selectIsCustomerUser, selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCustomerRequests } from 'ux/Dashboard/CustomerRequests/requests';

import NavIcon from './NavIcon';
import Navatar from './Navatar';
import SidebarItems from './SidebarItems';
import { REFERRALS_NAV_ITEM } from './constants';
import { isNavigationItemActive } from '../utils';

import { ReactComponent as SidebarCLogo } from './sidebar-c-logo.svg';
import { ReactComponent as SidebarLogo } from './sidebar-logo.svg';

interface Props {
  items: NavItem[];
  theme: 'light' | 'dark';
  avatarUrl?: string;
  isExpandable?: boolean;
}

const THEME = {
  light: { color: 'gray-25' },
  dark: { color: 'blue-25' }
} as const;

const Sidebar: React.FC<Props> = ({ theme, items, avatarUrl }) => {
  const { pathname, search } = useLocation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const uxType = useAppSelector(selectUIState).uxType;
  const user = useAppSelector(selectUser);
  const userId = user?.id;
  const isCustomerUser = useAppSelector(selectIsCustomerUser);
  const dispatch = useAppDispatch();

  const isAppPath = pathname.startsWith(APP_ROOT_PATH);
  const { appBannerType } = useAppSelector(selectUIState);

  const supportNavIcon = (
    <NavIcon
      iconName="callout"
      label={SUPPORT_LABEL}
      theme={theme}
      isActive={false}
      onClick={() => launchChatWidget()}
      isExpanded={isExpanded}
      index={3}
    />
  );

  useEffect(() => {
    if (!userId) return;
    // The requests sidebar nav item has a dot indicator if there are open requests
    getCustomerRequests({ userId }).then(({ data }) => dispatch(userActions.setCustomerRequests(data)));
  }, [userId, dispatch]);

  const height = isAppPath && appBannerType ? `calc(100vh - ${APP_BANNER_HEIGHT}px)` : '100vh';
  const appBannerIsVisible = appBannerType && isAppPath;

  return (
    <>
      <Layout
        width={SIDEBAR_PRIMARY_WIDTH}
        height={height}
        position="sticky"
        top={appBannerIsVisible ? APP_BANNER_HEIGHT : 0}
        flexShrink={0}
      />

      <Layout
        width={isExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_PRIMARY_WIDTH}
        color={THEME[theme].color}
        paddingTop={24}
        height={height}
        position="fixed"
        top={appBannerIsVisible ? APP_BANNER_HEIGHT : 0}
        zIndex={HEADER_Z_INDEX + 1}
        flexShrink={0}
        direction="column"
        justify="space-between"
        paddingBottom={32}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => !menuOpen && setIsExpanded(false)}
        transition="width 160ms ease-in-out"
      >
        <Layout paddingX={SIDEBAR_PADDING_X}>
          <Layout paddingLeft={2} align="center" justify="space-between">
            <Layout position="relative">
              <SidebarCLogo style={{ display: 'inline-flex' }} />
              <Layout top={0} left={2} position="absolute" opacity={isExpanded ? 1 : 0} transition="opacity 60ms">
                <SidebarLogo />
              </Layout>
            </Layout>
          </Layout>
          <Layout marginTop={24}>
            <SidebarItems items={items} isExpanded={isExpanded} theme={theme} />
          </Layout>
        </Layout>
        <Layout paddingX={12}>
          {!isProduction() && (
            <Layout marginBottom={8}>
              <NavIcon
                iconName="externalLink"
                label="QA Link"
                theme={theme}
                isActive={false}
                isExpanded={isExpanded}
                onClick={() => {
                  const params = new URLSearchParams();
                  params.append('path', pathname);

                  if (search) {
                    params.append('search', search);
                  }

                  if (user) {
                    params.append('email', user.email);
                  }

                  const url = new URL(QA_LINK + '?' + params.toString(), getBaseUrl()).toString();
                  copy(url);
                  showSnackbar({ message: 'Copied' });
                }}
              />
            </Layout>
          )}
          {isAppPath && (
            <>
              {uxType === 'customer' && isCustomerUser && (
                <Layout marginBottom={8}>
                  <NavIcon
                    iconName={REFERRALS_NAV_ITEM.iconName}
                    href={REFERRALS_NAV_ITEM.href}
                    label={REFERRALS_NAV_ITEM.label}
                    theme={theme}
                    isActive={isNavigationItemActive({ href: REFERRALS_PATH, exact: true, pathname })}
                    isExpanded={isExpanded}
                  />
                </Layout>
              )}
              {supportNavIcon}
              <Layout borderTop marginTop={8} marginBottom={12} borderColor="blue-200" />
            </>
          )}
          {avatarUrl && (
            <Navatar
              avatarUrl={avatarUrl}
              isExpanded={isExpanded}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              setIsExpanded={setIsExpanded}
            />
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default Sidebar;
