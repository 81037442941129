import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowScroll } from 'beautiful-react-hooks';
import classNames from 'classnames';

import { HEADER_Z_INDEX, Layout, NavItem } from 'ds';
import { actions } from 'store/UI';
import { selectSelectedOfficeId } from 'store/UI/selectors';
import { selectUIState } from 'store/UI/selectors';
import { selectCustomerRequests, selectHasCustomerAdminRole } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import NavIcon from './NavIcon';
import { CUSTOMER_OFFICE_PATHS } from './constants';
import { injectWorkspaceId } from './utils';
import { hrefIsMatch, isNavigationItemActive } from '../utils';

interface Props {
  items: NavItem[];
  theme: 'light' | 'dark';
  moreNavItems: NavItem[];
}

const BottomNavigation: React.FC<Props> = ({ items, theme, moreNavItems = [] }) => {
  const { pathname } = useLocation();
  const { bottomNavigationIsVisible } = useAppSelector(selectUIState);
  const scrollY = useRef<number>(window.scrollY);
  const initialScrollUpY = useRef<number>();
  const initialScrollDownY = useRef<number>();
  const dispatch = useAppDispatch();

  const requests = useAppSelector(selectCustomerRequests);
  const openRequests = requests.filter(request => request.status === 'open');
  const isCustomerAdmin = useAppSelector(selectHasCustomerAdminRole);

  const hasOpenRequests = !!openRequests.length;

  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);
  const shouldDisplayDot = (label: string) => {
    return (label === 'Requests' && hasOpenRequests) || label === 'Favorites';
  };

  const handleScroll = () => {
    if (initialScrollUpY.current && initialScrollUpY.current - window.scrollY > 30) {
      dispatch(actions.updateUIState({ bottomNavigationIsVisible: true }));
      initialScrollUpY.current = undefined;
      initialScrollDownY.current = undefined;
    } else if (window.scrollY < scrollY.current && !initialScrollUpY.current) {
      initialScrollUpY.current = window.scrollY;
    } else if (
      initialScrollDownY.current &&
      window.scrollY > scrollY.current &&
      window.scrollY > 100 &&
      window.scrollY - initialScrollDownY.current > 30
    ) {
      dispatch(actions.updateUIState({ bottomNavigationIsVisible: false }));
      initialScrollUpY.current = undefined;
      initialScrollDownY.current = undefined;
    } else if (window.scrollY > scrollY.current && !initialScrollDownY.current) {
      initialScrollDownY.current = window.scrollY;
    } else if (window.scrollY < 30) {
      dispatch(actions.updateUIState({ bottomNavigationIsVisible: true }));
    }

    scrollY.current = window.scrollY;
  };
  const onWindowScroll = useWindowScroll();
  onWindowScroll(handleScroll);

  return (
    <Layout
      zIndex={HEADER_Z_INDEX}
      color={theme === 'light' ? 'white' : 'blue-800'}
      width="100%"
      paddingX={12}
      __className={classNames('BottomNavigation BottomNavigationSlide', {
        'bottom-navigation-is-visible': bottomNavigationIsVisible
      })}
      boxShadow="rgba(87, 73, 109, 0.12) 2px 0 22px 0"
    >
      <Layout flex>
        {items.map(({ key, iconName, href, label, exact, matchHrefs, mobileLabel }) => {
          const isMoreItem = label === 'More';

          const injectedHref =
            isCustomerAdmin && CUSTOMER_OFFICE_PATHS.map(o => o.label).includes(label) && href && selectedOfficeId
              ? injectWorkspaceId({ href, selectedOfficeId })
              : href;

          const isItemInMoreNavItems = moreNavItems.some(({ href, exact, matchHrefs, label }) => {
            const injectedHref =
              isCustomerAdmin && CUSTOMER_OFFICE_PATHS.map(o => o.label).includes(label) && href && selectedOfficeId
                ? injectWorkspaceId({ href, selectedOfficeId })
                : href;

            return (
              hrefIsMatch({ href: injectedHref, exact, pathname }) ||
              !!matchHrefs?.some(matchedHref => {
                const injectedHref =
                  typeof matchedHref.href === 'string' &&
                  isCustomerAdmin &&
                  CUSTOMER_OFFICE_PATHS.map(o => o.label).includes(label) &&
                  matchedHref.href &&
                  selectedOfficeId
                    ? injectWorkspaceId({ href: matchedHref.href, selectedOfficeId })
                    : matchedHref.href;
                return hrefIsMatch({ href: injectedHref, exact: matchedHref.exact, pathname });
              })
            );
          });

          return (
            <Layout width={`${(100 / items.length).toFixed(2)}%`} key={label} justify="center">
              <NavIcon
                key={key}
                iconName={iconName}
                href={injectedHref}
                label={mobileLabel ? mobileLabel : label}
                theme={theme}
                displayDot={shouldDisplayDot(label)}
                isActive={
                  isNavigationItemActive({ href: injectedHref, matchHrefs, exact, pathname }) ||
                  (isMoreItem && isItemInMoreNavItems)
                }
              />
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default BottomNavigation;
