import { MutableRefObject } from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';

import { CustomerTableColumn, CustomerTableRow } from './types';
import { defaultAccessor } from './utils';

interface Props<T extends CustomerTableRow> extends CustomerTableColumn<T> {
  columnIndex: number;
  rowIndex: number;
  columnLength: number;
  __ref?: MutableRefObject<HTMLDivElement | null>;
  row: T;
  measuredWidth?: number;
  id: string;
}

const CustomerTableCell = <T extends CustomerTableRow>({
  width,
  minWidth,
  name,
  accessor,
  Cell: CellComponent,
  columnIndex,
  row,
  id,
  columnLength,
  measuredWidth,
  rowIndex,
  __ref
}: Props<T>) => {
  const isFirstRow = rowIndex === 0;
  const isRightAligned = columnIndex === columnLength - 1;

  return (
    <Layout
      paddingX={24}
      align="center"
      justify={isRightAligned ? 'flex-end' : undefined}
      key={name}
      __ref={__ref}
      {...(isFirstRow
        ? {
            width,
            minWidth,
            equalWidth: !(width || minWidth),
            flexShrink: width || minWidth ? 0 : undefined
          }
        : { width: measuredWidth, flexShrink: width || minWidth ? 0 : undefined })}
    >
      {CellComponent ? (
        <CellComponent {...row} />
      ) : (
        <Text size="body2">{accessor ? accessor(row) : defaultAccessor(row, id)}</Text>
      )}
    </Layout>
  );
};

export default CustomerTableCell;
