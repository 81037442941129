import {
  Listing,
  PayoutAccount,
  SavedWorkspace,
  TaskSchedule,
  Updatable,
  User,
  safeAssign,
  sortByDate,
  updateInList
} from '@codiwork/codi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AdminListOfficeLead, AdminListTour } from 'ux/Admin/Customer/Leads/types';
import { AdminDetailCustomer, AdminListCustomer, AdminListPlan, AdminListReferral } from 'ux/Admin/Customers/types';
import { AdminDetailLicensor } from 'ux/Admin/Licensors/Licensor/types';
import { AdminListLicenseAgreement, AdminListLicensor } from 'ux/Admin/Licensors/types';
import { AdminProduct } from 'ux/Admin/Products/types';
import { AdminDetailShortTermBooking, AdminListShortTermBooking } from 'ux/Admin/ShortTermBookings/types';
import { AdminTask } from 'ux/Admin/Tasks/types';
import { AdminReferralConfig } from 'ux/Admin/Tools/AdminReferralConfigs/types';
import { ListSearchLocation } from 'ux/Admin/Tools/Locations/types';
import { AdminListUser } from 'ux/Admin/Users/types';
import { AdminListWorkspace } from 'ux/Admin/Workspaces/types';
import { AdminStartupRequest } from 'ux/Admin/api_requests';

interface ReduxListSearchLocation extends ListSearchLocation {
  id: string;
}

export type AdminState = {
  workspaces: AdminListWorkspace[];
  workspace: SavedWorkspace | null;
  users: AdminListUser[];
  licensors: AdminListLicensor[];
  licensor: AdminDetailLicensor | null;
  licenseAgreements: AdminListLicenseAgreement[];
  user: User | null;
  customers: AdminListCustomer[];
  customer: AdminDetailCustomer | null;
  officeLeads: AdminListOfficeLead[];
  plans: AdminListPlan[];
  tours: AdminListTour[];
  products: AdminProduct[] | null;
  mapWorkspaces: Listing[];
  tasks: AdminTask[];
  taskSchedules: TaskSchedule[];
  shortTermBookings: AdminListShortTermBooking[];
  global: AdminStartupRequest | null;
  locations: ReduxListSearchLocation[];
  referrals: AdminListReferral[];
  referralConfigs: AdminReferralConfig[];
};

const initialState: AdminState = {
  workspaces: [],
  mapWorkspaces: [],
  workspace: null,
  users: [],
  user: null,
  licensors: [],
  licensor: null,
  licenseAgreements: [],
  customers: [],
  customer: null,
  officeLeads: [],
  plans: [],
  tours: [],
  products: [],
  tasks: [],
  taskSchedules: [],
  shortTermBookings: [],
  global: null,
  locations: [],
  referrals: [],
  referralConfigs: []
};

const adminSlice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    setWorkspaces: (state, { payload }: PayloadAction<AdminListWorkspace[]>) => {
      state.workspaces = payload;
    },
    setWorkspace: (state, { payload }: PayloadAction<SavedWorkspace | null>) => {
      state.workspace = payload;
    },
    updateWorkspace: (state, { payload }: PayloadAction<Partial<SavedWorkspace>>) => {
      safeAssign(state.workspace, payload);
    },
    updateWorkspaceById: (state, { payload }: PayloadAction<Updatable<AdminListWorkspace>>) => {
      updateInList(state.workspaces, payload);
    },
    setUsers: (state, { payload }: PayloadAction<AdminListUser[]>) => {
      state.users = payload;
    },
    updateUserById: (state, { payload }: PayloadAction<Updatable<AdminListUser>>) => {
      updateInList(state.users, payload);
    },
    updateListUser: (state, { payload }: PayloadAction<Updatable<AdminListUser>>) => {
      updateInList(state.users, payload);
    },
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
    updateUser: (state, { payload }: PayloadAction<Updatable<User>>) => {
      safeAssign(state.user, payload);
    },
    setLicensors: (state, { payload }: PayloadAction<AdminListLicensor[]>) => {
      state.licensors = payload;
    },
    setLicensor: (state, { payload }: PayloadAction<AdminDetailLicensor | null>) => {
      state.licensor = payload;
    },
    setLicensorPayoutAccount: (state, { payload }: PayloadAction<PayoutAccount>) => {
      if (!state.licensor) return;

      state.licensor.payout_account = payload;
    },
    setLicenseAgreements: (state, { payload }: PayloadAction<AdminListLicenseAgreement[]>) => {
      state.licenseAgreements = payload;
    },
    setCustomers: (state, { payload }: PayloadAction<AdminListCustomer[]>) => {
      state.customers = payload;
    },
    setCustomer: (state, { payload }: PayloadAction<AdminDetailCustomer | null>) => {
      state.customer = payload;
    },
    setOfficeLeads: (state, { payload }: PayloadAction<AdminListOfficeLead[]>) => {
      state.officeLeads = payload;
    },
    setPlans: (state, { payload }: PayloadAction<AdminListPlan[]>) => {
      state.plans = payload;
    },
    setTours: (state, { payload }: PayloadAction<AdminListTour[]>) => {
      state.tours = payload;
    },
    setReferrals: (state, { payload }: PayloadAction<AdminListReferral[]>) => {
      state.referrals = payload;
    },
    setReferralConfigs: (state, { payload }: PayloadAction<AdminReferralConfig[]>) => {
      state.referralConfigs = payload;
    },
    updateReferralConfig: (state, { payload }: PayloadAction<Updatable<AdminReferralConfig>>) => {
      updateInList(state.referralConfigs, payload);
    },
    updateStb: (state, { payload }: PayloadAction<AdminDetailShortTermBooking>) => {
      if (!state.customer) return;

      const { short_term_bookings } = state.customer;

      updateInList(short_term_bookings, payload);
    },
    updateCustomerById: (state, { payload }: PayloadAction<Updatable<AdminListCustomer>>) => {
      updateInList(state.customers, payload);
    },
    setProducts: (state, { payload }: PayloadAction<AdminProduct[]>) => {
      state.products = payload;
    },
    updateProductById: (state, { payload }: PayloadAction<AdminProduct>) => {
      if (!state.products) {
        state.products = [payload];
      } else {
        updateInList(state.products || [], payload);
      }
    },
    setMapWorkspaces: (state, { payload }: PayloadAction<Listing[]>) => {
      state.mapWorkspaces = payload;
    },
    setTasks: (state, { payload }: PayloadAction<AdminTask[]>) => {
      state.tasks = payload;
    },
    addOrUpdateTask: (state, { payload }: PayloadAction<AdminTask>) => {
      const existingTaskIndex = state.tasks.findIndex(r => r.id === payload.id);

      if (existingTaskIndex > -1) {
        state.tasks[existingTaskIndex] = payload;
      } else {
        state.tasks.push(payload);
      }
      state.tasks.sort((r1, r2) => sortByDate(r1.due_date, r2.due_date));
    },
    removeTask: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.tasks = state.tasks.filter(r => r.id !== payload.id);
    },
    setTaskSchedules: (state, { payload }: PayloadAction<TaskSchedule[]>) => {
      state.taskSchedules = payload;
    },
    setShortTermBookings: (state, { payload }: PayloadAction<AdminListShortTermBooking[]>) => {
      state.shortTermBookings = payload;
    },
    setGlobal: (state, { payload }: PayloadAction<AdminStartupRequest>) => {
      state.global = payload;
    },
    setLocations: (state, { payload }: PayloadAction<ListSearchLocation[]>) => {
      state.locations = payload.map(p => ({ ...p, id: p.guid }));
    },
    updateLocation: (state, { payload }: PayloadAction<Updatable<ReduxListSearchLocation>>) => {
      updateInList(state.locations, payload);
    }
  }
});

export const { actions } = adminSlice;

export default adminSlice.reducer;
