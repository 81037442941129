import { CustomerUserWorkspace, LicensorUserRole, Office, User, WorkspaceUserRole, sortByDate } from '@codiwork/codi';
import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { DateTime } from 'luxon';

import { selectProducts, selectWeeklyCleaningProduct, selectWeeklyTouchUpProduct } from 'store/App/selectors';
import { selectSelectedOfficeId } from 'store/UI/selectors';
import { RootState } from 'store/store';

export const selectUser = (state: RootState) => state.User.user;
export const selectUserLoaded = (state: RootState) => selectUser(state) !== undefined;
export const selectIsLoggedOut = (state: RootState) => selectUser(state) === null;
export const selectUserId = (state: RootState) => state.User.user?.id;
// Use in components that require a user
export const selectLoggedInUserId = (state: RootState) => state.User.user?.id as number;
export const selectIsCustomerUser = (state: RootState) => !!state.User.user?.customer;
export const selectIsAdmin = (state: RootState) => !!state.User.user?.is_admin;

export const selectIsContractor = (state: RootState) => !!state.User.user?.is_contractor;
export const selectAssignedTasks = (state: RootState) => state.User.user?.assigned_tasks || [];
export const selectAssignedTaskById = (state: RootState, id: number) =>
  state.User.user?.assigned_tasks?.find(t => t.id === id);

export const selectListingAdminWorkspaces = (state: RootState) => state.User.user?.listing_admin_workspaces;
export const selectLicensorRoles = (state: RootState) => state.User.user?.licensor_roles || [];
export const selectHasPayoutAdminRole = (state: RootState) => {
  const licensorsWithPayoutAdminRole = (state.User.user?.licensor_roles || []).find(l =>
    l.roles.includes('payout_admin')
  );

  return !!licensorsWithPayoutAdminRole;
};
export const selectHasPayoutViewerRole = (state: RootState) => {
  const licensorsWithPayoutViewerRole = (state.User.user?.licensor_roles || []).find(l =>
    l.roles.includes('payout_viewer')
  );

  return !!licensorsWithPayoutViewerRole;
};
export const selectHasListingAdminRole = (state: RootState) => {
  return !!(state.User.user?.listing_admin_workspaces || []).length;
};

export const selectHasListingAdminRoleForWorkspace = (state: RootState, workspaceId: number) => {
  return !!(state.User.user?.listing_admin_workspaces || []).find(ws => ws.id === workspaceId);
};

export const selectHasPropertiesNavItem = (state: RootState) => {
  const user = state.User.user;
  if (!user) return false;

  return (
    selectHasPayoutAdminRole(state) ||
    selectHasPayoutViewerRole(state) ||
    selectHasListingAdminRole(state) ||
    !!user.is_workspace_creator
  );
};
export const selectCustomerAdminPlans = (state: RootState) => state.User.user?.customer_admin_plans;
export const selectCustomerAdminPlan = (planId: number) =>
  createSelector(selectCustomerAdminPlans, customerAdminPlans =>
    customerAdminPlans?.long_term_plans.find(ltp => ltp.id === planId)
  );

export const selectActiveWorkspace = (planId: number) =>
  createSelector(selectCustomerAdminPlan(planId), selectCustomerAdminWorkspaces, (plan, workspaces) => {
    if (!plan) return;

    const workspace = workspaces.find(w => w.id === plan.active_workspace_id);

    return workspace;
  });

export const selectHasCustomerAdminRole = (state: RootState) => !!state.User.user?.customer_admin_plans;
export const selectHasSignedPlans = (state: RootState) =>
  !!state.User.user?.customer_admin_plans &&
  !!state.User.user.customer_admin_plans.long_term_plans.find(ltp => !!ltp.contract_signed_date);
export const selectUserCustomers = (state: RootState) => state.User.user?.customers;
export const selectIsCustomer = (state: RootState) => !!selectUserCustomers(state)?.length;
export const selectOfficeLeads = (state: RootState) => state.User.user?.office_leads || [];

export const selectCustomerAdminCustomer = (state: RootState) => {
  const customerAdminPlans = selectCustomerAdminPlans(state);

  if (customerAdminPlans) {
    return customerAdminPlans.customer;
  }
};

// NOTE: includes phone booths
export const selectFurnitureAndEquipmentAddOns = (planId: number) =>
  createSelector(selectRecurringAddOns(planId), recurringAddOns => {
    const furnitureAndEquipmentAddOns = recurringAddOns.filter(
      ao => ao.product_id && ao.product?.category !== 'services'
    );

    return furnitureAndEquipmentAddOns;
  });

export const selectRecurringAddOns = (planId: number) =>
  createSelector(selectCustomerAdminPlans, selectProducts, (customerAdminPlans, products) => {
    const plan = customerAdminPlans?.long_term_plans.find(ltp => ltp.id === planId);
    if (!plan) return [];

    const today = DateTime.now();

    const addOns = plan.add_on_orders.map(ao => ao.add_ons).flat();
    const recurringAddOns = addOns
      .filter(ao => ao.recurring)
      .map(i => ({ ...i, product: products.find(p => p.id === i.product_id) }))
      .filter(ao => (!ao.end_date || DateTime.fromISO(ao.end_date) <= today) && ao.recurring && ao.recurring);

    return recurringAddOns;
  });

export const selectWeeklyCleaningAddOns = (planId: number) =>
  createSelector(
    selectRecurringAddOns(planId),
    selectWeeklyCleaningProduct,
    (recurringAddOns, weeklyCleaningProduct) => {
      return recurringAddOns.filter(ao => ao.product?.id === weeklyCleaningProduct?.id);
    }
  );

export const selectWeeklyTouchUpAddOns = (planId: number) =>
  createSelector(selectRecurringAddOns(planId), selectWeeklyTouchUpProduct, (recurringAddOns, weeklyTouchUpProduct) => {
    return recurringAddOns.filter(ao => ao.product?.id === weeklyTouchUpProduct?.id);
  });

export const selectLongTermPlans = createSelector(
  selectCustomerAdminPlans,
  customerAdminPlans => customerAdminPlans?.long_term_plans || []
);

export const selectAddOnOrders = createSelector(selectLongTermPlans, longTermPlans =>
  longTermPlans.map(plan => plan.add_on_orders).flat()
);

export const selectPendingAddOnOrders = createSelector(selectAddOnOrders, addOnOrders =>
  addOnOrders.filter(addOn => addOn.status === 'pending')
);

export const selectPlansWithAddOnOrders = createSelector(
  selectLongTermPlans,
  longTermPlans => longTermPlans.filter(plan => !!plan.add_on_orders.length) || []
);
export const selectBillingHasCommenced = (state: RootState) => {
  const customerAdminPlans = selectCustomerAdminPlans(state);
  return !!customerAdminPlans?.long_term_plans.find(ltp => !!ltp.contract_signed_date);
};
export const selectCustomerAdminWorkspaces = (state: RootState): CustomerUserWorkspace[] => {
  const customerAdminPlans = selectCustomerAdminPlans(state);
  const workspaceIds = customerAdminPlans?.active_workspace_ids;

  if (!workspaceIds) return [];

  return selectAvailableWorkspaces(state)?.filter(ws => workspaceIds.includes(ws.id)) || [];
};
export const selectActivePlansAndWorkspaces = createSelector(
  selectLongTermPlans,
  selectCustomerAdminWorkspaces,
  (plans, workspaces) => {
    const today = DateTime.now();
    return plans
      .filter(plan => {
        const isActive = !plan.end_date || DateTime.fromISO(plan.end_date) >= today;
        return isActive && !!workspaces.find(ws => ws.id === plan.active_workspace_id);
      })
      .map(plan => {
        const workspace = workspaces.find(ws => ws.id === plan.active_workspace_id) as CustomerUserWorkspace;
        const hasStarted = DateTime.fromISO(plan.start_date) <= today;

        return { ...plan, workspace, hasStarted };
      });
  }
);

export const selectOffices = createSelector(selectUser, user => user?.offices || []);
export const selectOffice = createSelector(
  selectOffices,
  (_state: RootState, workspaceId: number) => workspaceId,
  (offices, workspaceId) => offices.find(o => o.id === workspaceId) || []
);

export const selectActiveOffices = createSelector(selectOffices, offices => offices.filter(o => !!o.active));

export const selectUpcomingOffices = createSelector(selectOffices, offices => offices.filter(o => !!o.upcoming));
export const selectIsCustomerAdmin = (state: RootState) => {
  return !!selectCustomerAdminPlans(state);
};
export const selectUserCalendar = (state: RootState) => state.User.calendar;
export const selectAvailableWorkspace = (state: RootState, id: number) =>
  (selectAvailableWorkspaces(state) || []).find(ws => ws.id === id);
export const selectAvailableWorkspaces = (state: RootState) => state.User.availableWorkspaces;
export const userHasRole = (user: User, role: LicensorUserRole | WorkspaceUserRole | 'admin' | 'customer'): boolean => {
  if (role === 'admin') {
    return user.is_admin;
  } else if (role === 'customer') {
    return !!user.customer;
  } else {
    const licensorsWithRole = (user.licensor_roles || []).filter(licensor =>
      licensor.roles.includes(role as LicensorUserRole)
    );
    const workspacesWithRole = (user.workspace_roles || []).filter(workspace =>
      workspace.roles.includes(role as WorkspaceUserRole)
    );

    return !!licensorsWithRole.length || !!workspacesWithRole.length;
  }
};
export const selectUserHasAgreements = (state: RootState) =>
  !!(state.User.user?.customer_admin_plans?.long_term_plans || []).length;
export const selectPayments = (state: RootState) => state.User.payments;
export const selectUpcomingPayments = (state: RootState) => state.User.upcomingPayments;
export const selectCustomerAdminReservations = (state: RootState) => state.User.reservations;
export const selectReservationWorkspaces = createSelector(selectUserCalendar, calendar => {
  if (!calendar) return [];

  return calendar.workspaces.filter(ws => ws.reservable_type === 'reservation');
});

export const selectSwitcheableWorkspaceIds = createSelector(
  selectCustomerAdminPlans,
  selectReservationWorkspaces,
  (customerAdminPlans, reservationWorkspaces) =>
    uniq([
      ...(customerAdminPlans?.long_term_plans.map(p => p.active_workspace_ids).flat() || []),
      ...reservationWorkspaces.map(ws => ws.id)
    ])
);

export const selectCustomerAdminWorkspaceIds = createSelector(selectCustomerAdminPlans, customerAdminPlans =>
  uniq([...(customerAdminPlans?.long_term_plans.map(p => p.active_workspace_ids).flat() || [])])
);

export const selectSelectedOfficeInCustomerAdminOffices = createSelector(
  selectCustomerAdminWorkspaceIds,
  selectSelectedOfficeId,
  (customerAdminWorkspaceIds, selectedOfficeId) =>
    !!selectedOfficeId && customerAdminWorkspaceIds.includes(selectedOfficeId)
);

export const selectHasActiveReservationsOrOffsites = (state: RootState) => {
  const calendar = selectUserCalendar(state);

  return !!calendar && calendar.workspaces.length > 0;
};
export const selectOfficeLeadWorkspaces = createSelector(
  selectOfficeLeads,
  (_state: RootState, officeLeadId?: number) => officeLeadId,
  (officeLeads, officeLeadId) => {
    if (officeLeadId) {
      return officeLeads.find(ol => ol.id === officeLeadId)?.workspaces || [];
    } else {
      return officeLeads.map(ol => ol.workspaces).flat();
    }
  }
);
export const selectAddedOfficeLeadWorkspaces = (state: RootState, officeLeadId: number) =>
  selectOfficeLeadWorkspaces(state, officeLeadId).filter(ws => ws.user_interactions.some(ui => ui.status === 'added'));

export const selectRecommendedWorkspaces = createSelector(
  selectOfficeLeadWorkspaces,
  selectUserId,
  (officeLeadWorkspaces, userId) => {
    return officeLeadWorkspaces.filter(
      ws => !!ws.user_interactions.find(ui => ui.status === 'proposed' && ui.user.id === userId && !ui.added_at)
    );
  }
);

export const selectOfficeInteractions = createSelector(
  selectOfficeLeads,
  (_state: RootState, officeLeadId: number) => officeLeadId,
  (officeLeads, officeLeadId) => {
    const officeLead = officeLeads.find(ol => ol.id === officeLeadId);

    if (!officeLead) return [];

    return officeLead.workspaces.map(ws => ws.user_interactions).flat();
  }
);

/** All liked workspaces for user */
export const selectUserLikedWorkspaceIds = createSelector(
  selectOfficeInteractions,
  selectUserId,
  (officeInteractions, userId) => {
    return officeInteractions.filter(ui => ui.status === 'added' && ui.user.id === userId).map(ui => ui.workspace_id);
  }
);

export const selectUserOffsites = createSelector(
  (state: RootState) => state.User.user?.offsites || [],
  selectOffices,
  (offsites, offices) => {
    return (
      offsites.map(offsite => ({
        ...offsite,
        workspace: offices.find(o => o.id === offsite.workspace_id) as Office
      })) || []
    );
  }
);

export const selectUserHasOffsites = (state: RootState) => !!selectUserOffsites(state).length;

export const selectIsProspect = (state: RootState) => !!state.User.user?.office_leads.length;
export const selectSortedCustomerRequests = (state: RootState) => state.User.requests || [];
export const selectCustomerRequests = createSelector(
  selectSortedCustomerRequests,
  requests => [...requests].sort((r1, r2) => sortByDate(r2.created_at, r1.created_at)) || []
);
export const selectOpenCustomerRequests = createSelector(
  selectCustomerRequests,
  requests => requests.filter(({ status }) => status !== 'done') || []
);
export const selectResolvedCustomerRequests = createSelector(
  selectCustomerRequests,
  requests => requests.filter(({ status }) => status === 'done') || []
);
export const selectReferralInfo = (state: RootState) => state.User.user?.referral_info;
export const selectUserActions = (state: RootState) => state.User.user?.user_actions || [];
export const selectReferralInvites = (state: RootState) => state.User.referralInvites;
export const selectCoworkers = (state: RootState) => state.User.user?.customer?.coworkers || [];
export const selectTourDestinationRequests = (state: RootState) => {
  if (!state.User.user) return [];

  const officeLeads = state.User.user.office_leads;

  return officeLeads
    .map(ol => ol.tours)
    .flat()
    .filter(t => ['pending', 'scheduled'].includes(t.status))
    .map(t => t.destination_requests)
    .flat();
};
export const selectActiveFeatures = (state: RootState) => state.User.user?.active_features || [];
