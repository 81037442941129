import React from 'react';

import ButtonPrivate, { Props as ButtonPrivateProps } from './ButtonPrivate';
import { BUTTON_TYPE_CONFIG } from './constants';
import { ButtonBorderRadius, ButtonType } from './types';

export interface Props
  extends Pick<
    ButtonPrivateProps,
    | 'onClick'
    | 'disabled'
    | 'icon'
    | 'iconPosition'
    | 'iconStroke'
    | 'showSpinner'
    | 'tabIndex'
    | 'htmlType'
    | '__ref'
    | 'text'
    | 'href'
    | 'search'
    | 'textSize'
  > {
  type: ButtonType;
  size?: 'sm' | 'md';
  borderRadius?: ButtonBorderRadius;
}

const CONFIG = {
  sm: {
    iconSize: 'xs',
    size: 'pill-sm'
  },
  md: {
    iconSize: 'sm',
    size: 'pill-md'
  }
} as const;

const PillButton: React.FC<Props> = ({ type, size = 'sm', ...props }) => {
  const config = { ...BUTTON_TYPE_CONFIG[type] };
  const { iconSize, size: buttonSize } = CONFIG[size];

  return (
    <ButtonPrivate borderRadius={16} size={buttonSize} iconGutter={4} {...props} {...config} iconSize={iconSize} />
  );
};

export default PillButton;
