import { PropsWithChildren } from 'react';
import { COLORS, Color, ValidationResult, escapeRegExp } from '@codiwork/codi';
import IMask from 'imask';
import { AsYouType, CountryCode } from 'libphonenumber-js';
// @ts-ignore
import exampleNumbers from 'libphonenumber-js/examples.mobile.json';
import { DateTime, Interval } from 'luxon';

import { Media, ScreenSizeBase, getScreenSizeBase } from 'context';

import { COUNTRY_DATA, DAYS_OF_WEEK_ORDERED, FIELD_HEIGHT_LG, FIELD_HEIGHT_MD, FIELD_HEIGHT_SM } from './constants';
import { FieldSize } from './types';

// e.g. flexDirection => flex-direction
export function camelToKebabCase(camelCase: string): string {
  return camelCase.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}
export function kebabToCamelCase(kebab: string): string {
  return kebab
    .split('-')
    .map((word, index) => (index ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
    .join('');
}

export function assignProp<T, K extends keyof T>(propGroup: T, key: K, value: T[K]) {
  propGroup[key] = value;
}

export function getScaledFieldHeight(media: Media): number {
  return screenSizeToFieldHeight(getScreenSizeBase(media));
}

export function screenSizeToFieldHeight(screenSize: ScreenSizeBase): number {
  switch (screenSize) {
    case 'lg':
      return FIELD_HEIGHT_LG;
    case 'md':
      return FIELD_HEIGHT_MD;
    default:
      return FIELD_HEIGHT_SM;
  }
}

export function sizeToFieldHeight(fieldSize: FieldSize): number {
  switch (fieldSize) {
    case 'lg':
      return FIELD_HEIGHT_LG;
    case 'md':
      return FIELD_HEIGHT_MD;
    default:
      return FIELD_HEIGHT_SM;
  }
}

export const extractText = (node?: JSX.Element | string | JSX.Element[]): string => {
  return processNode(node)
    .replace(/\s{2,}/g, ' ')
    .trim();
};

const processNode = (node?: JSX.Element | string | JSX.Element[]) => {
  try {
    if (!node) {
      return '';
    } else if (typeof node === 'string' || typeof node === 'number') {
      return node + ' ';
    } else if (Array.isArray(node)) {
      return node.map((child: JSX.Element | string) => extractText(child) + ' ').join('');
    } else if (node?.type?.name === 'Icon') {
      return node.props.name + ' ';
    } else {
      const children = node?.props?.children;

      if (children) {
        return extractText(children);
      }

      return '';
    }
  } catch (e) {
    console.error({ error: e, node });
    return '';
  }
};

export function getCountryFromCallingCode(callingCode = '1'): CountryCode {
  return COUNTRY_DATA.find(countryData => countryData.callingCode === callingCode)?.country || 'US';
}

export const formatsFromPhoneNumber = ({ callingCode = '1' }: { callingCode: string }) => {
  const country = getCountryFromCallingCode(callingCode);
  const number = exampleNumbers[country];
  const phoneNumberFormatted = new AsYouType(country === 'US' ? country : undefined).input(
    country === 'US' ? number : `+${callingCode}${number}`
  );

  return {
    placeholder: phoneNumberFormatted.replace(`+${callingCode} `, '').replace(new RegExp('[0-9]', 'g'), 'X'),
    maskFormat: phoneNumberFormatted.replace(`+${callingCode} `, '').replace(new RegExp('[0-9]', 'g'), '0') // '(000) 000-0000'
  };
};

export function formatPhoneNumber({ callingCode = '1', phone }: { callingCode?: string; phone: string }): string {
  const { maskFormat } = formatsFromPhoneNumber({ callingCode });
  const mask = IMask.createMask({
    mask: maskFormat
  });

  return `+${callingCode} ${mask.resolve(phone)}`;
}

export function highlightMatches({
  label,
  query,
  MatchedWrapper
}: {
  label: string;
  query?: string;
  MatchedWrapper: React.FC<PropsWithChildren>;
}): string | JSX.Element {
  if (!query) {
    return label;
  }

  const match = label.match(new RegExp(escapeRegExp(query), 'i'));

  if (!match) {
    return label;
  }

  const matched = match[0];
  const { index } = match;

  if (index === undefined) {
    return label;
  }

  return (
    <>
      {label.slice(0, index)}
      <MatchedWrapper>{matched}</MatchedWrapper>
      {label.slice(index + matched.length)}
    </>
  );
}

export function hoverBgColorClass(color: Color | 'blue-gradient'): string {
  return `hover-bg-color-${color}`;
}

export function bgColorClass(color: Color | 'blue-gradient'): string {
  return `bg-color-${color}`;
}

const NOTHERN_POSITIONS = ['nw', 'n', 'ne'];
const SOUTHERN_POSITIONS = ['sw', 's', 'se'];
const NS_POSITIONS = ['s', 'n'];
const EW_POSITIONS = ['e', 'w'];
const NS_EAST_POSITIONS = ['ne', 'se'];
const NS_WEST_POSITIONS = ['nw', 'sw'];

type Position = 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw';
const TOOLTIP_CARET_DIMENSION = 12;
const TOOLTIP_CARET_SPACE = 14;
const TOOLTIP_SPACE = 4;

type Rect = { top: number; left: number; width: number; height: number };
type Dimensions = { width: number; height: number };

export function getTooltipCoords({
  contentRect,
  position,
  tooltipDimensions,
  offsetTop = 0
}: {
  contentRect: Rect;
  position: Position;
  tooltipDimensions: Dimensions;
  offsetTop?: number;
}): { top: number; left: number } {
  let top = 0;
  let left = 0;
  const diagonalnumber = getDiagonalOfSquare(TOOLTIP_CARET_DIMENSION);

  if (SOUTHERN_POSITIONS.includes(position)) {
    top = contentRect.top + contentRect.height + TOOLTIP_SPACE + diagonalnumber / 2;
  } else if (EW_POSITIONS.includes(position)) {
    top = contentRect.top - tooltipDimensions.height / 2 + contentRect.height / 2;
  } else if (NOTHERN_POSITIONS.includes(position)) {
    top = contentRect.top - tooltipDimensions.height - diagonalnumber / 2 - TOOLTIP_SPACE;
  }

  if (NS_POSITIONS.includes(position)) {
    left = contentRect.left - tooltipDimensions.width / 2 + contentRect.width / 2;
  } else if (NS_WEST_POSITIONS.includes(position)) {
    left =
      contentRect.left - tooltipDimensions.width + contentRect.width / 2 + diagonalnumber / 2 + TOOLTIP_CARET_SPACE;
  } else if (NS_EAST_POSITIONS.includes(position)) {
    left = contentRect.left + (contentRect.width / 2 - (TOOLTIP_CARET_SPACE + diagonalnumber / 2));
  } else if (position === 'w') {
    left = contentRect.left - tooltipDimensions.width - diagonalnumber / 2 - TOOLTIP_SPACE;
  } else if (position === 'e') {
    left = contentRect.left + contentRect.width + diagonalnumber / 2 + TOOLTIP_SPACE;
  }

  return { top: top + offsetTop, left };
}

function getDiagonalOfSquare(dimension: number): number {
  return Math.sqrt(Math.pow(dimension, 2) * 2);
}

export type Replacement = {
  value: string;
  variable: 'location' | 'competitor' | 'companyName';
};

export function replaceValues(value?: string, replacements?: Replacement[]): string {
  if (!value || !replacements) return '';

  let replaced = value;

  replacements.forEach(({ value, variable }) => {
    replaced = replaced.replaceAll(`{{${variable}}}`, value);
  });

  return replaced;
}

export const preventScroll = (e: Event) => {
  e.preventDefault();
};

export function getRootElement() {
  return document.getElementById('root');
}

export function getDatePickerPortalElement() {
  return document.getElementById('codi-date-picker-portal');
}

export function lockScreenScroll() {
  const rootElement = getRootElement();

  if (!rootElement) return;

  rootElement.classList.add('is-scroll-locked');
  document.body.classList.add('is-scroll-locked');
}

export function unlockScreenScroll() {
  const rootElement = getRootElement();

  if (!rootElement) return;

  rootElement.classList.remove('is-scroll-locked');
  document.body.classList.remove('is-scroll-locked');

  rootElement.removeEventListener('scroll', preventScroll);
}

export function setRootBackgroundColor(color: Color | null) {
  const rootElement = getRootElement();

  if (!rootElement) return;

  if (!color) {
    rootElement.style.removeProperty('background-color');
  } else {
    rootElement.style.backgroundColor = COLORS[color];
  }
}

export function daysOfWeek({
  firstDay,
  includeWeekend = false
}: {
  firstDay?: 'Sunday' | 'Monday';
  includeWeekend?: boolean;
}) {
  if (firstDay === 'Sunday') {
    const days = [...DAYS_OF_WEEK_ORDERED];
    const sunday = days[6];
    return days.slice(0, 6).concat(sunday);
  }

  if (includeWeekend) {
    return [...DAYS_OF_WEEK_ORDERED];
  } else {
    return [...DAYS_OF_WEEK_ORDERED].slice(0, 5);
  }
}

export function ordinalToDayOfWeek({ ordinal }: { ordinal: number }) {
  const day = DAYS_OF_WEEK_ORDERED.find(day => day.ordinal === ordinal);
  return day;
}

export function formatMoneyK(amount: number, precision: number = 1) {
  return `$${formatNumberK(amount, precision)}`;
}

export function formatMoneyM(amount: number, precision: number = 1) {
  return `$${parseFloat((amount / 1000000).toFixed(precision)).toLocaleString()}M`;
}

export function formatNumberK(value: number, precision: number = 1) {
  return `${parseFloat((value / 1000).toFixed(precision)).toLocaleString()}K`;
}

export const validateHostName = ({
  value,
  required,
  hostname
}: {
  value?: string;
  required?: boolean;
  hostname: string;
}): ValidationResult => {
  if (!value && required) {
    return { valid: false, error: `${hostname} link is required` };
  } else if (!value) {
    return { valid: true };
  }

  try {
    const url = new URL(value);

    if (url.hostname !== hostname) {
      return { valid: false, error: `Must be a link from ${hostname}` };
    }

    return { valid: true };
  } catch {
    return { valid: false, error: `Must be a link from ${hostname}` };
  }
};

/** e.g. 17:00 to 5:00 PM */
export function timeValueToDisplay(time: string) {
  return DateTime.fromFormat(time, 'HH:mm').toFormat('h:mm a');
}

export function makeTourDateOptions() {
  let firstMonday: DateTime;
  const now = DateTime.local().startOf('day');

  if (now.weekday >= 0 && now.weekday <= 2) {
    firstMonday = now.set({ weekday: 1 });
  } else {
    firstMonday = now.set({ weekday: 1 }).plus({ weeks: 1 });
  }

  const businessDayShift = now.weekday >= 3 && now.weekday <= 5 ? 2 : now.weekday === 6 ? 1 : 0;

  const firstAvailableDate = now.plus({ days: 3 + businessDayShift });

  const weekOne = new Array(5).fill(0).map((_, index) => {
    const dateTime = firstMonday.plus({ days: index });

    return {
      dateTime,
      disabled: dateTime < firstAvailableDate
    };
  });

  const nextMonday = firstMonday.plus({ weeks: 1 });
  const weekTwo = new Array(5)
    .fill(0)
    .map((_, index) => ({ dateTime: nextMonday.plus({ days: index }), disabled: false }));

  return [weekOne, weekTwo];
}

export function makeDatesList({ startDateTime, endDateTime }: { startDateTime: DateTime; endDateTime: DateTime }) {
  const dateList = Interval.fromDateTimes(startDateTime.startOf('day'), endDateTime.endOf('day'))
    .splitBy({ day: 1 })
    .map(d => d.start);

  return dateList;
}

export const namePartsToFirstLastInitial = ({
  firstname,
  lastname
}: {
  firstname: string | null;
  lastname: string | null;
}): string => {
  if (!firstname && !lastname) {
    return '';
  } else if (!lastname) {
    return firstname || '';
  }

  return `${firstname} ${lastname[0].toUpperCase()}.`;
};

export const HASH_LINK_REGEXP = /^\/.+#[a-z0-9-]+$/;
