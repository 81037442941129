import React, { PropsWithChildren, createContext } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';

// isMobile aims to target all mobile touch devices like iPads and phones.
export type Media = Record<ScreenSize | 'media500', boolean> & { xxl: boolean } & {
  isMobile: boolean;
};

const MediaContext = createContext<Media>({
  xxs: false,
  media500: false,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  isMobile: false
});

export const XXS = 380;
export const XS = 375;
// All phones in portrait mode
export const FIVE_HUNDRED = 500;
export const SM = 768;
export const MD = 900;
export const LG = 1200;
export const XL = 1440;
export const XXL = 1680;

export const XXS_QUERY = `(min-width: 0) and (max-width: ${XXS}px)`;
export const FIVE_HUNDRED_QUERY = `(min-width: 0) and (max-width: ${FIVE_HUNDRED - 1}px)`;
export const XS_QUERY = `(min-width: 0) and (max-width: ${SM - 1}px)`;
export const SM_QUERY = `(min-width: ${SM}px) and (max-width: ${MD - 1}px)`;
export const MD_QUERY = `(min-width: ${MD}px) and (max-width: ${LG - 1}px)`;
export const LG_QUERY = `(min-width: ${LG}px)`;
export const XL_QUERY = `(min-width: ${XL}px)`;
export const XXL_QUERY = `(min-width: ${XXL}px)`;

export const MediaProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const xxs = useMediaQuery(XXS_QUERY);
  const media500 = useMediaQuery(FIVE_HUNDRED_QUERY);
  const xs = useMediaQuery(XS_QUERY);
  const sm = useMediaQuery(SM_QUERY);
  const md = useMediaQuery(MD_QUERY);
  const lg = useMediaQuery(LG_QUERY);
  const xl = useMediaQuery(XL_QUERY);
  const xxl = useMediaQuery(XXL_QUERY);
  const isMobile = xs || sm;

  return (
    <MediaContext.Provider value={{ xxs, xs, sm, md, lg, xl, xxl, media500, isMobile }}>
      {children}
    </MediaContext.Provider>
  );
};

export function getScreenSize(media: Media): ScreenSize {
  const { xxs, sm, md, lg, xl } = media;

  if (xl) {
    return 'xl';
  } else if (lg) {
    return 'lg';
  } else if (md) {
    return 'md';
  } else if (sm) {
    return 'sm';
  } else if (xxs) {
    return 'xxs';
  } else {
    return 'xs';
  }
}

export function getScreenSizeBase(media: Media): ScreenSizeBase {
  const size = getScreenSize(media);

  if (size === 'xl') {
    return 'lg';
  } else if (size === 'xxs') {
    return 'xs';
  } else {
    return size;
  }
}

export function getScreenSizeXxs(media: Media): ScreenSizeXxs {
  const size = getScreenSize(media);

  if (size === 'xl') {
    return 'lg';
  } else {
    return size;
  }
}

export function getScreenSizeXl(media: Media): ScreenSizeXl {
  const size = getScreenSize(media);

  if (size === 'xxs') {
    return 'xs';
  } else {
    return size;
  }
}

export const SCREEN_SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;
export const SCREEN_SIZE_BASE = ['xs', 'sm', 'md', 'lg'] as const;
export type ScreenSize = typeof SCREEN_SIZES[number];
export type ScreenSizeBase = typeof SCREEN_SIZE_BASE[number];
export type ScreenSizeXxs = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
export type ScreenSizeXl = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export default MediaContext;
