import React, { useEffect } from 'react';

import { HUBSPOT_CHAT_SCRIPT_ID, INITIAL_HUBSPOT_CONVERSATION_SETTINGS } from 'helpers/constants';
import { isProduction } from 'helpers/environment';

import useScript from 'hooks/useScript';

let widget_loaded = false;

export function launchChatWidget() {
  if (!widget_loaded) {
    window.HubSpotConversations.widget.load({ widgetOpen: false });
    widget_loaded = true;
  }

  window.HubSpotConversations.widget.open();
}

interface Props {
  email: string;
  hubspot_token: string;
}

window.hsConversationsSettings = INITIAL_HUBSPOT_CONVERSATION_SETTINGS;

const HubSpotChatWidgetManager: React.FC<Props> = ({ email, hubspot_token }) => {
  const _status = useScript(isProduction() ? '//js.hs-scripts.com/39932238.js' : '//js.hs-scripts.com/39986598.js', {
    id: HUBSPOT_CHAT_SCRIPT_ID
  });

  useEffect(() => {
    window.hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: hubspot_token,
      ...INITIAL_HUBSPOT_CONVERSATION_SETTINGS
    };

    return () => {
      widget_loaded = false;
      window.hsConversationsSettings = INITIAL_HUBSPOT_CONVERSATION_SETTINGS;
    };
  }, [email, hubspot_token]);

  return null;
};

export default HubSpotChatWidgetManager;
